<template>
  <section id="dashboard-partner">
    <b-row class="match-height">
      <b-col lg="12">
        <b-card class="partnerCard">
          <div class="d-flex flex-wrap justify-content-end">
            <div class="p-1 d-flex">
              <date-picker
                v-model="dateRange"
                lang="en"
                type="date"
                range
                format="MMMM DD, YYYY"
                placeholder="Select Date Range"
                range-separator=" - "
                class="custom-datepicker-filter"
                calendar-class="my-datepicker_calendar"
              >
              </date-picker>
            </div>
            <div
              class="p-1 d-flex"
              v-if="
                userData.default_region_id ==
                  'f06c8247-3b89-4ab5-8bb7-3734bf931c53' &&
                (userData.role_name == 'GLOBAL_ADMIN' ||
                  userData.role_name == 'ACCOUNT_MANAGER')
              "
            >
              <b-form-select
                v-model="selectedCurrency"
                :options="currencyOptions"
                value-field="code"
                text-field="code"
              />
            </div>
            <div class="p-1 d-flex">
              <b-button variant="primary" class="mr-2" @click="filterDate">
                <div class="d-flex align-items-center">
                  <b-spinner
                    small
                    label="Text Centered"
                    v-if="isLoading"
                  ></b-spinner>
                  <span class="button-title">Filter</span>
                </div>
              </b-button>
              <b-button
                variant="primary"
                @click="clear"
                :disabled="dateRange == ''"
              >
                <div class="d-flex align-items-center">
                  <b-spinner
                    small
                    label="Text Centered"
                    v-if="isLoad"
                  ></b-spinner>
                  <span class="button-title">Clear</span>
                </div>
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="6" sm="12">
        <b-card
          header="Google Cloud Spend"
          class="card-height-partner font-weight-bold"
        >
          <loading
            :active.sync="loading"
            :can-cancel="true"
            :is-full-page="false"
            color="#4285f4"
            loader="dots"
          />
          <LineChartGenerator
            v-show="!loading"
            :chart-options="lineChartOptions"
            :chart-data="lineChartData"
            responsive="true"
          />
        </b-card>
      </b-col>

      <b-col lg="6" sm="12">
        <b-card
          header="Top Google Cloud Services"
          class="card-height-partner font-weight-bold"
        >
          <loading
            :active.sync="loading"
            :can-cancel="true"
            :is-full-page="false"
            color="#4285f4"
            loader="dots"
          />
          <Bar
            v-show="!loading"
            :chart-options="barChartOptions"
            :chart-data="barChartData"
            responsive="true"
            class="mt-1"
          />
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12">
        <b-card>
          <template #header>
            <div class="d-flex justify-content-start">
              <span class="font-weight-bold">Top movers</span>
            </div>
            <div
              class="d-flex justify-content-end"
              v-if="trendStartDate !== ''"
            >
              <span class="font-weight-bold"
                >VS. {{ convertDate(trendStartDate) }} :
                {{ convertDate(trendEndDate) }}</span
              >
            </div>
          </template>

          <loading
            :active.sync="loading"
            :can-cancel="true"
            :is-full-page="false"
            color="#4285f4"
            loader="dots"
          />
          <b-table
            v-show="!loading"
            id="partner"
            :items="tableItems"
            :fields="tableColumns"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            show-empty
            empty-text="No matching records found"
            responsive
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template #head()="data">
              <span v-if="data.label == 'Customer'">
                {{ data.label }}
              </span>
              <span v-if="data.label == 'Customer Cost'">
                {{ data.label }}
                <feather-icon
                  icon="InfoIcon"
                  size="12"
                  v-b-tooltip.hover.right="
                    'Expected cost for the resold customer. It does not include your Google Reseller Program discount, offline credits, or tax. It shows any available repricing data for dates starting December 2022 or later.'
                  "
                />
              </span>
              <span v-if="data.label == 'Net Cost'">
                {{ data.label }}
                <feather-icon
                  icon="InfoIcon"
                  size="12"
                  v-b-tooltip.hover.right="
                    'The total usage-based costs for reseller charges. Includes your Google Reseller Program discount, negotiated savings, SUDs, CUDs, and spending-based discounts. Net cost does not include offline credits, repricing, or tax.'
                  "
                />
              </span>
              <span v-if="data.label == 'Gross Profit'">
                {{ data.label }}
                <feather-icon
                  icon="InfoIcon"
                  size="12"
                  v-b-tooltip.hover.right="
                    'Your total profit, shown as the difference between customer cost and net cost. This is equivalent to your Reseller margin credit and excludes offline credits and repricing.'
                  "
                />
              </span>
              <span v-if="data.label == 'Trend'">
                {{ data.label }}
                <feather-icon
                  icon="InfoIcon"
                  size="12"
                  v-b-tooltip.hover.right="
                    'The difference between the customer cost over the selected date range (6/1/23 - 6/13/23) and the customer cost over the immediately preceding date range of the same length (5/19/23 - 5/31/23).'
                  "
                />
              </span>
            </template>

            <template #cell(customer)="data">
              <span>{{ data.item.customer }}</span>
            </template>

            <template #cell(customer_cost)="data">
              {{
                data.item.customer_cost.toFixed(2)
                  | toCurrency(data.item.currency)
              }}
            </template>

            <template #cell(net_cost)="data">
              {{
                data.item.net_cost.toFixed(2) | toCurrency(data.item.currency)
              }}
            </template>

            <template #cell(gross_profit)="data">
              {{
                data.item.gross_profit.toFixed(2)
                  | toCurrency(data.item.currency)
              }}
            </template>

            <template #cell(trend)="data">
              <span v-if="data.item.trend_arrow == 'Down'" class="trend-down">
                <feather-icon icon="TrendingDownIcon" size="18" />
                {{
                  data.item.trend.toFixed(2) | toCurrency(data.item.currency)
                }}
              </span>
              <span v-if="data.item.trend_arrow == 'Up'" class="trend-up">
                <feather-icon icon="TrendingUpIcon" size="18" />
                {{
                  data.item.trend.toFixed(2) | toCurrency(data.item.currency)
                }}
              </span>
            </template>
          </b-table>
          <b-card class="border-top mb-0">
            <b-row>
              <!-- Pagination -->
              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  aria-controls="my-table"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormSelect,
  BTable,
  BPagination,
  BButton,
  BSpinner,
  VBTooltip,
} from "bootstrap-vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import DatePicker from "vue2-datepicker/index.esm";
import "vue2-datepicker/index.css";
import vSelect from "vue-select";
import store from "@/store";
import axios from "@axios";
import { Line as LineChartGenerator, Bar as Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  components: {
    BRow,
    BFormSelect,
    BCol,
    BCard,
    BTable,
    BPagination,
    BButton,
    BSpinner,
    VBTooltip,
    LineChartGenerator,
    Bar,
    DatePicker,
    vSelect,
    Loading,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      loading: false,
      isLoading: false,
      isLoad: false,
      sortBy: "",
      isSortDirDesc: true,
      selectedCurrency: null,
      currentPage: 1,
      perPage: 10,
      currencyOptions: [],
      dateRange: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      startDate: "",
      endDate: "",
      trendStartDate: "",
      trendEndDate: "",
      lineChartData: {
        labels: [],
        datasets: [],
      },
      lineChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            intersect: false,
            interaction: {
              mode: "index",
            },
          },
          display: true,
          legend: {
            align: "end",
            labels: {
              boxWidth: 10,
              boxHeight: 2,
              padding: 20,
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {},
          },
          y: {
            title: {
              display: true,
              text: "Cost",
              color: "#5e5873",
              font: {
                size: 14,
                weight: "bold",
                family: "Montserrat, Helvetica, Arial, serif",
              },
            },
            ticks: {
              callback: function (value) {
                if (store.state.app.currencyType == "USD") {
                  return "$ " + value;
                } else if (store.state.app.currencyType == "GBP") {
                  return "£ " + value;
                } else if (store.state.app.currencyType == "EUR") {
                  return "€ " + value;
                }
              },
            },
          },
        },
        hover: {
          mode: "index",
          intersect: false,
        },
      },
      barChartData: {
        labels: [],
        datasets: [],
      },
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {},
          },
          y: {
            title: {
              display: true,
              text: "Cost",
              color: "#5e5873",
              font: {
                size: 14,
                weight: "bold",
                family: "Montserrat, Helvetica, Arial, serif",
              },
            },
            ticks: {
              callback: function (value) {
                if (store.state.app.currencyType == "USD") {
                  return "$ " + value;
                } else if (store.state.app.currencyType == "GBP") {
                  return "£ " + value;
                } else if (store.state.app.currencyType == "EUR") {
                  return "€ " + value;
                }
              },
            },
          },
        },
      },
      tableItems: [],
      tableColumns: [
        { key: "customer" },
        { key: "customer_cost" },
        { key: "net_cost" },
        { key: "gross_profit" },
        { key: "trend", sortable: true },
      ],
    };
  },
  computed: {
    rows() {
      return this.tableItems.length;
    },
  },
  created() {
    if (
      this.userData.default_region_id == "f06c8247-3b89-4ab5-8bb7-3734bf931c53"
    ) {
      const regionCurrencyData = JSON.parse(
        localStorage.getItem("regionCurrencyData")
      );
      for (const [key, value] of Object.entries(
        regionCurrencyData.regions_details
      )) {
        this.currencyOptions = value.currencies;
      }
    }
    this.selectedCurrency = this.userData.deafult_currency_code;
    store.commit("app/CURRENCY_TYPE", this.userData.deafult_currency_code);
  },
  watch: {
    selectedCurrency(newValue) {
      if (newValue) {
        store.commit("app/CURRENCY_TYPE", newValue);
      }
    },
    "$store.state.app.userData.default_region_id": {
      handler() {
        this.userData = JSON.parse(localStorage.getItem("userData"));
        this.dateRange = "";
        this.dateRange = [];
        this.currentPage = 1;
        this.selectedCurrency = this.userData.deafult_currency_code;
        if (
          this.userData.default_region_id ==
          "f06c8247-3b89-4ab5-8bb7-3734bf931c53"
        ) {
          const regionCurrencyData = JSON.parse(
            localStorage.getItem("regionCurrencyData")
          );
          for (const [key, value] of Object.entries(
            regionCurrencyData.regions_details
          )) {
            this.currencyOptions = value.currencies;
          }
        }
        this.getCurrentMonthDates();
        this.getUniqueAccountDetails();
      },
      immediate: false,
    },
    "$store.state.appConfig.layout.skin": {
      handler() {
        if (store.state.appConfig.layout.skin == "dark") {
          this.lineChartOptions.plugins.legend.labels.color = "#FFFFFF";
          this.lineChartOptions.scales.y.title.color = "#FFFFFF";
          this.lineChartOptions.scales.x.ticks.color = "#D0D2D6";
          this.lineChartOptions.scales.y.ticks.color = "#D0D2D6";

          this.barChartOptions.scales.y.title.color = "#FFFFFF";
          this.barChartOptions.scales.x.ticks.color = "#D0D2D6";
          this.barChartOptions.scales.y.ticks.color = "#D0D2D6";
        } else {
          this.lineChartOptions.plugins.legend.labels.color = "#5e5873";
          this.lineChartOptions.scales.y.title.color = "#5e5873";
          this.lineChartOptions.scales.x.ticks.color = "#6E6B7B";
          this.lineChartOptions.scales.y.ticks.color = "#6E6B7B";

          this.barChartOptions.scales.y.title.color = "#FFFFFF";
          this.barChartOptions.scales.x.ticks.color = "#6E6B7B";
          this.barChartOptions.scales.y.ticks.color = "#6E6B7B";
        }
      },
      immediate: false,
    },
  },
  beforeMount() {
    if (store.state.appConfig.layout.skin == "dark") {
      this.lineChartOptions.plugins.legend.labels.color = "#FFFFFF";
      this.lineChartOptions.scales.y.title.color = "#FFFFFF";
      this.lineChartOptions.scales.x.ticks.color = "#D0D2D6";
      this.lineChartOptions.scales.y.ticks.color = "#D0D2D6";

      this.barChartOptions.scales.y.title.color = "#FFFFFF";
      this.barChartOptions.scales.x.ticks.color = "#D0D2D6";
      this.barChartOptions.scales.y.ticks.color = "#D0D2D6";
    } else {
      this.lineChartOptions.plugins.legend.labels.color = "#5e5873";
      this.lineChartOptions.scales.y.title.color = "#5e5873";
      this.lineChartOptions.scales.x.ticks.color = "#6E6B7B";
      this.lineChartOptions.scales.y.ticks.color = "#6E6B7B";

      this.barChartOptions.scales.y.title.color = "#5e5873";
      this.barChartOptions.scales.x.ticks.color = "#6E6B7B";
      this.barChartOptions.scales.y.ticks.color = "#6E6B7B";
    }
    this.getCurrentMonthDates();
    this.getUniqueAccountDetails();
  },
  methods: {
    clear() {
      this.isLoad = true;
      this.dateRange = "";
      this.dateRange = [];
      this.getCurrentMonthDates();
      this.getUniqueAccountDetails();
    },
    filterDate() {
      this.isLoading = true;
      if (this.dateRange.length > 0) {
        this.startDate = this.dateRange[0];
        this.endDate = this.dateRange[1];
      }
      if (this.dateRange[0] == null) {
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 30);
        this.endDate = new Date();
        this.endDate.setDate(this.endDate.getDate());
      }
      this.getUniqueAccountDetails();
    },
    getCurrentMonthDates() {
      this.startDate = new Date();
      this.startDate.setDate(this.startDate.getDate() - 30);
      this.endDate = new Date();
      this.endDate.setDate(this.endDate.getDate());
      this.dateRange = [];
      this.dateRange.push(this.startDate);
      this.dateRange.push(this.endDate);
    },
    convertDate(inputFormat) {
      function pad(s) {
        return s < 10 ? "0" + s : s;
      }
      let d = new Date(inputFormat),
        month = pad(d.getMonth() + 1),
        day = pad(d.getDate()),
        year = d.getFullYear();
      if (
        this.userData.default_region_id ==
        "0afb05a8-da40-41d9-9e51-e288e6db7789"
      ) {
        return [month, day, year].join("-");
      } else {
        return [day, month, year].join("-");
      }
    },
    convertDateForPassing(inputFormat) {
      function pad(s) {
        return s < 10 ? "0" + s : s;
      }
      let d = new Date(inputFormat);
      return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join(
        "-"
      );
    },
    getUniqueAccountDetails() {
      this.loading = true;
      this.trendStartDate = "";
      this.trendEndDate = "";
      let formatedStartDate = this.convertDateForPassing(this.startDate);
      let formatedEndDate = this.convertDateForPassing(this.endDate);

      let pramsObj;
      pramsObj = {
        currency:
          this.userData.default_region_id ==
          "f06c8247-3b89-4ab5-8bb7-3734bf931c53"
            ? this.selectedCurrency
            : "USD",
      };

      axios
        .get(
          `/gcp-spend/partner_sale/${this.userData.default_region_id}?start_date=${formatedStartDate}&end_date=${formatedEndDate}`,
          {
            params: pramsObj,
          }
        )
        .then((response) => {
          this.loading = false;
          this.isLoading = false;
          this.isLoad = false;
          this.lineChartData.datasets = [];
          this.lineChartData.labels = [];
          this.barChartData.datasets = [];
          this.barChartData.labels = [];
          this.tableItems = [];

          this.trendStartDate =
            response.data.partner_sales_report.trend_start_date;
          this.trendEndDate = response.data.partner_sales_report.trend_end_date;

          // this.selectedDates =
          //   response.data.partner_sales_report.multi_line_data.labels.map((v) =>
          //     this.convertDate(new Date(v))
          //   );

          const days = [];

          const dateStart = new Date(this.startDate);
          const dateEnd = new Date(this.endDate);

          while (dateStart <= dateEnd) {
            if (dateStart == dateEnd) {
              days.push(new Date(dateStart));
              break;
            } else {
              days.push(new Date(dateStart));
              dateStart.setDate(dateStart.getDate() + 1);
            }
          }

          this.selectedDates = days.map((v) => this.convertDate(v));

          this.lineChartData.datasets =
            response.data.partner_sales_report.multi_line_data.datasets;
          this.lineChartData.labels = this.selectedDates;
          this.barChartData.datasets =
            response.data.partner_sales_report.bar_data.datasets;
          this.barChartData.labels =
            response.data.partner_sales_report.bar_data.labels;

          response.data.partner_sales_report.table_data.sort(function (a, b) {
            return parseFloat(b.trend) - parseFloat(a.trend);
          });

          this.tableItems = response.data.partner_sales_report.table_data;
        })
        .catch(() => {
          this.loading = false;
          this.isLoading = false;
          this.isLoad = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";

.card-height-partner {
  min-height: 26rem !important;
  border: 1px solid $partner-border-color;
  color: $headings-color;
}
.card-header {
  border-bottom: 1px solid $partner-border-color !important;
  padding: 10px 10px 10px 15px !important;
}
#dashboard-partner > div:nth-child(1) > div > div > div {
  padding: 0.5rem;
}
#partner > thead > tr > th {
  border-top: none !important;
}
#dashboard-partner > div:nth-child(3) > div > div > div.card-body {
  padding: 0 !important;
}
.trend-down {
  color: $trend-down-color;
}
.trend-up {
  color: $trend-up-color;
}
.custom-datepicker-filter {
  width: 100%;
}
.mx-input {
  height: 37px;
}
</style>
